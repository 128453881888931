import { React, useLayoutEffect } from 'react'
import "../css/Green.css";

// -------------swiper------------------
import {Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// -------------images--------------------
import park from "../images/gallery/garden.jpg";
import f1 from "../images/royal farm house/fruit1.jpg";
import f2 from "../images/royal farm house/fruit1.jpg";
import dine from "../images/royal farm house/dinning.jpg";
import pond from "../images/royal farm house/pond.jpg";
import hall from "../images/royal farm house/hall.jpg";
import roof from "../images/royal farm house/rooftop.jpg";
import hut from "../images/royal farm house/hut.jpg";
import flower1 from "../images/royal farm house/flower.jpg";
import white from "../images/gallery/white-flower.jpg";
import whitepink from "../images/royal farm house/whie-pink.jpg";
import tree from "../images/gallery/tree.jpg";
import rose from "../images/royal farm house/pink-rose.jpg";
import cake from "../images/royal farm house/rooftop.jpg";
import Footer from "../component/Footer";
import swim from "../images/royal farm house/swim2.jpg";
import rfarm from "../images/royal farm house/1.jpg";
import { MetaTags } from 'react-meta-tags';
const Royalfarm = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (

    <div>
      {/* ========= SEO ========== */}
      <MetaTags>
        <title>
        4bhk Villa near Neral | Green Farm House
        </title>
        <meta name="title" title="4bhk Villa near Neral | Green Farm House"/>
        <meta
          property="og:title"
          content="4bhk Villa near Neral | Green Farm House"
        />
        <meta
          name="description"
          content="Discover 4BHK villa near Neral, the perfect blend of elegance and nature. Your dream home awaits in this serene haven."
        />
       
        <meta
          property="og:description"
          content="Discover 4BHK villa near Neral, the perfect blend of elegance and nature. Your dream home awaits in this serene haven."
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta property="article:tag" content="Farmhouse in Neral with Swimming Pool" />
        <link rel="canonical" href="https://www.greenfarmhouseneral.com/4bhk-villa-near-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>
      {/* ======== Seo end ======= */}
<div class="pages-hero">
            <div class="container">
                <div class="pages-title">
                    <h5>Grown by nature</h5>
                   <h1 className='d-none'> 4bhk Villa near Neral</h1>
                    <h2>Royal Farm House</h2>
                    <p>Immerse yourself in our lush greenery.</p>
                </div>
            </div>
        </div>



        <section>
  <div className="container mt-5 mb-5">
    <div className="row">
    <div className="col-lg-6">
      <div className="home-about">
        
              <h2>Royal Farm</h2>
              <h3>
                Royal Farm House 
              </h3>
              </div>
              <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              // breakpoints={{
              //   320: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   640: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //   },
              //   1024: {
              //     slidesPerView: 3,
              //     spaceBetween: 50,
              //   },
              // }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >

<SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={rfarm} alt="2bhk bunglow" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={roof} alt="roof top with sitting area" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={hut} alt="hut at farm" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={hall} alt="spacious hall at farm" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={dine} alt="dinning area at farm" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={rfarm} alt="garden area at farm" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={swim} alt="swimming pool at farm" />
          </figure>
         
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={swim} alt="kids pool at farm" />
          </figure>
        
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={swim} alt="pool at farm" />
          </figure>
       
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={swim} alt="swimming pool" />
          </figure>
       
        </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={swim} alt="swimming pool" />
          </figure>

        </div>
            </SwiperSlide>
          </Swiper>   
       <p>4BHK Villa in Neral offers an opulent escape like no other as our exquisite accommodations consist of four spacious rooms, each with the option of air conditioning or non-air conditioning, providing comfort that suits your preference. These rooms come complete with attached toilets, separate entries for privacy, and private balconies where you can revel in the scenic beauty that surrounds you.
</p>
<p>4BHK Villa in Neral is the ideal destination for both intimate gatherings and larger celebrations, with the capacity to accommodate up to 50 guests. Our elite terrace offers a luxurious space for you to unwind and enjoy the splendid views.
</p>
<p>
              
              For those seeking aquatic bliss, our resort boasts a lavish swimming pool and a baby pool, both maintained with an imported filter plant to ensure the purest water quality. 
              
                          </p>
      </div>
      <div className="col-lg-6 spacing-md">
      <div className="product-caption">
           
            <p>
              {" "}
              Planning a special event? Our 2000-square-foot lawn area is the perfect venue for small functions, adding a touch of elegance to your celebrations. Relish a diverse array of culinary delights with our delectable veg and non-veg meal options, prepared to perfection.
            </p>
     <p>Parking is never a concern at 4BHK Villa in Neral , as we offer ample space to ensure the safety of your vehicles. Engage in recreational activities and various indoor and outdoor games, promising entertainment for all ages.
</p>
<p>No matter the circumstances, our generator backup ensures uninterrupted comfort during your stay. At 4BHK Villa in Neral , we invite you to experience a regal retreat where luxury, natural beauty, and recreation come together to create memories that will last a lifetime.
</p>
          </div>
        <aside className="aside-right">
          <div className="inner-aside pt-lg-2">
          
            <h5>Amenities</h5>
            <ul className="list-group categorie-list">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                4 Rooms (AC/NoN AC) with attached toilet, separate entry & balcony.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              50 Persons Accomocations.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Elite Terrace.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Swimming Pool & Baby Pool with imported filter plant.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Lawns & Children Play Area with Swings, Seasaw, Fishing and Games etc.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                2000sqft. Lawn area for small functions
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Veg/Non-veg Meals
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
            Ample Parking space, Generator Backup
              </li>
            
          
              
            </ul>
          </div>
     
          
        
        </aside>
      </div>
    </div>
  </div>
</section>



    
<div className="container mt-5 mb-5">
        <div className="section-title">
          <h2>View </h2>
          <h3>Our Gallery</h3>
          <p>
          Visit Green farm and feel real beauty of nature.
          </p>
        </div>
        <div className="team-carousel">
          <Swiper
            // navigation={true}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={flower1} alt="flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={pond} alt="duck pond" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={cake} alt="garden" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={park} alt="park" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f1} alt="flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={whitepink} alt="pink rose" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={rose} alt="red rose" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={white} alt="white flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f2} alt="flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={tree} alt="trees" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

       
      </div>
<Footer/>
    </div>
  )
}

export default Royalfarm