import React from "react";
import {Link} from 'react-router-dom';
import Footer from "../component/Footer";
const Errorpage = () => {
  return (
    <div>
      <div class="pages-hero">
        <div class="container">
          <div class="pages-title">
            {/* <h5>Love with Nature</h5> */}
            <h5 className="">Farm House near Neral</h5>
            <h2>404</h2>
          </div>
        </div>
      </div>

      <div className="service-wrapper-layer">
        <div className="container">
          <div className="section-title">
            <h2>404</h2>
            <h3>Page Not Found</h3>
            <p>
              There is an error in page .Back to Home&nbsp;<Link to="/">Home</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Errorpage;
