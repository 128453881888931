import { React, useLayoutEffect } from 'react'
import "../css/Green.css";
import Footer from "../component/Footer";
import gfarm from "../images/gallery/greenfarm.jpg";
import hut from "../images/green farm house/babyswim.jpg";
import pond from "../images/gallery/bonfire.jpg";
import horse from "../images/green farm house/garden.jpg";
import garden from "../images/green farm house/swim.jpg";
import swim from "../images/gallery/swim2.jpg";
// -------------swiper------------------
import {Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// -------------images--------------------
import park from "../images/green farm house/garden.jpg";
import f1 from "../images/green farm house/swingbed.jpg";
import f2 from "../images/green farm house/persons.jpg";
import flower1 from "../images/green farm house/farm.jpg";
import white from "../images/green farm house/garden1.jpg";
import whitepink from "../images/green farm house/bathroom2.jpg";
import tree from "../images/green farm house/basin.jpg";
import rose from "../images/green farm house/bathroom.jpg";
import {MetaTags} from "react-meta-tags";


const Greenfarm = () => {

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    });
  return (
    <div>
       {/* ========= SEO ========== */}
       <MetaTags>
        <title>
        2bhk Villa in Neral | Green Farm House
        </title>
        <meta name="title" title="2bhk Villa in Neral | Green Farm House"/>
        <meta
          property="og:title"
          content="2bhk Villa in Neral | Green Farm House"
        />
        <meta
          name="description"
          content="Explore our exquisite 2BHK Villa in Neral, a haven of luxury and comfort. Your dream home is just a click away. "
        />
       
        <meta
          property="og:description"
          content="Explore our exquisite 2BHK Villa in Neral, a haven of luxury and comfort. Your dream home is just a click away. "
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta property="article:tag" content="Farmhouse in Neral with Swimming Pool" />
        <link rel="canonical" href="https://www.greenfarmhouseneral.com/2bhk-villa-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>
      {/* ======== Seo end ======= */}
<div class="pages-hero">
            <div class="container">
                <div class="pages-title">
                    <h5>Grown by nature</h5>
                    <h1 className='d-none'>2bhk Villa in Neral </h1>
                    <h2>Green Farm House</h2>
                    <p>Immerse yourself in our lush greenery.</p>
                </div>
            </div>
        </div>
        <section>
  <div className="container mt-5 mb-5">
    <div className="row">
      <div className="col-lg-6">
      <div className="home-about">
              <h2>Green Farm</h2>
              <h3>
                Green Farm House 
              </h3>
              </div>
              <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              // breakpoints={{
              //   320: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   640: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //   },
              //   1024: {
              //     slidesPerView: 3,
              //     spaceBetween: 50,
              //   },
              // }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={gfarm} alt="4bhk villa" />
          </figure>
    
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={hut} alt="hut at farm" />
          </figure>
         
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={garden} alt="garden area at farm" />
          </figure>
        
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={horse} alt="horse ridding at farm" />
          </figure>
       
        </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={pond} alt="pond at farm" />
          </figure>
       
        </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="procuct-details">
          <figure className="product-featur-img">
            <img src={gfarm} alt="dinning at farm" />
          </figure>

        </div>
            </SwiperSlide>
          </Swiper>   
          <div className="product-caption">
            <p>
            2BHK Villa in Neral welcomes you to a blissful retreat in the lap of nature. Our charming 2BHK bungalows, available with or without air conditioning, offer comfortable and spacious accommodation, each equipped with attached toilets for your convenience. The open verandah of each bungalow provides a tranquil spot to enjoy the surrounding beauty.

            </p>
            <p>
              {" "}
              With the capacity to host up to 15 guests, 2BHK Villa in Neral is perfect for both small and large groups. Immerse yourself in our lush greenery, where a variety of fruit-bearing trees such as mango, chikoo, jackfruit, and coconut thrive, offering a taste of nature's bounty.
             
            </p>
            </div>
      </div>
      <div className="col-lg-6 spacing-md">
      <div className="product-caption">
           
           
            <p>For those seeking relaxation and recreation, our resort boasts a pristine swimming pool and a baby pool, complete with an imported filter plant for crystal-clear water. Our sprawling lawns and children's play area, featuring swings and a seesaw, ensure that every member of the family can have a delightful time.
</p>
            <p>2BHK Villa in Neral also provides ample parking space for your convenience, ensuring that your vehicles are secure during your stay. Even in the event of power outages, our generator backup ensures that your comfort is never compromised.

2BHK Villa in Neral offers an unforgettable escape from the hustle and bustle of city life, where nature, comfort, and recreation come together to create cherished memories.
</p>
     
          </div>
          <aside className="aside-right">
          <div className="inner-aside pt-lg-2">
         
            <h5>Amenities</h5>
            <ul className="list-group categorie-list">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                2BHK Bunglow (AC/Nov AC) with attached toilet ,open varandah
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              15 Persons Accomocations.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
            Fruits bearing trees like Mango/Chickoo/Jackfruit/Coconut
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Swimming Pool & Baby Pool with imported filter plant.
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Lawns & Children Play Area with Swings, Seasaw,  Horse Ridding available etc.
              </li>
             
              <li className="list-group-item d-flex justify-content-between align-items-center">
              Veg/Non-veg Meals
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
            Ample Parking space , Generator backup
              </li>
            
             
              
            </ul>
          </div>
     
          
        
        </aside>
      </div>
      
    </div>
  </div>
</section>

<div className="container mt-5 mb-5">
        <div className="section-title">
          <h2>View </h2>
          <h3>Our Gallery</h3>
          <p>
            Visit Green farm and feel real beauty of nature.
          </p>
        </div>
        <div className="team-carousel">
          <Swiper
            // navigation={true}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={flower1} alt="flowers at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={swim} alt="pool at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={park} alt="park at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f1} alt="pink flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={whitepink} alt="white flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={rose} alt="rose flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={white} alt="white roses" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f2} alt="marigold" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={tree} alt="fruit trees at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

       
      </div>
 <Footer/>
    </div>
  )
}

export default Greenfarm