import { React, useLayoutEffect } from 'react'
import "../css/About.css";
import whatsapp from "../images/whatsapp.png";
import farm5 from "../images/gallery/cottage.jpg";
import camera from "../images/icons/cctv.png";
import gen from  "../images/icons/generator.png";
import dj from "../images/icons/dj.png";
import blue from "../images/icons/speaker.png";
import wifi from "../images/icons/wifi.png";
import parking from "../images/icons/parking-lot.png";
import Footer from "../component/Footer";
import {MetaTags} from "react-meta-tags";
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <div>
        {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Farm House near Neral | Green Farm House
        </title>
        <meta name="title" title=" Farm House near Neral | Green Farm House"/>
        <meta
          property="og:title"
          content=" Farm House near Neral | Green Farm House"
        />
        <meta
          name="description"
          content="Discover our Farm House near Neral for a peaceful retreat. Reconnect with nature, enjoy serene surroundings, and unwind in style. Book your getaway today!"
        />
       
        <meta
          property="og:description"
          content="Discover our Farm House near Neral for a peaceful retreat. Reconnect with nature, enjoy serene surroundings, and unwind in style. Book your getaway today!"
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta property="article:tag" content="Farmhouse in Neral with Swimming Pool" />
        <link rel="canonical" href="https://www.greenfarmhouseneral.com/farm-house-near-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
        <div class="pages-hero">
            <div class="container">
                <div class="pages-title">
                    <h5>Love with Nature</h5>
                    <h1 className='d-none'>Farm House near Neral</h1>
                    <h2>About Us</h2>
                    <p>We provide comfort stay at farm.</p>
                </div>
            </div>
        </div>

        <section>
  <div className="container mt-5 mb-5">
    <div className="row">
      <div className="col-lg-6">
        <div className="about-info-layer left-title">
          <h5 className="subtitle"></h5>
          <h2>
Where Every Stay Becomes a Memorable Escape
</h2>
          <p>
          Nestled amidst the untouched beauty of nature,  Farmhouse near Neral beckons you to embark on a journey of tranquility and unforgettable memories. Our resort offers the perfect blend of rustic charm and modern comfort, providing a haven for those seeking respite from the urban chaos. 
          
          
          Whether you yearn for a romantic escape, a family adventure, or a solo retreat, our accommodations cater to all, promising a cozy and elegant retreat. Wake up to the melodious symphony of birds, inhale the rejuvenating countryside air, and begin your day with a smile as you immerse yourself in the picturesque landscapes that surround us.

          </p>
          <p>Farmhouse near Neral doesn't just offer a place to lay your head; it provides an array of activities designed to engage and entertain you throughout your stay. From leisurely nature walks and captivating bird-watching sessions to heart-pounding outdoor games and enchanting bonfires beneath a star-studded sky, every moment here is an opportunity to connect with nature and create cherished memories.</p>
          <div className="more-details-layer">
            <div className="media contact-layer d-flex">
              <img src={whatsapp} className="mr-3" alt="whatsapp at 9892258567 " />
              <div className="media-body">
                <h6>Contact us for Service</h6>
                <h3>
                <a href=" https://wa.me/9892258567"> 9892258567</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 spacing-md">
        <figure className="about-img-layer">
          <img src={farm5} alt="family farm in neral" />
          <div className="experience-layer">
            <h4>35 Years Of Experience</h4>
          </div>
        </figure>
      </div>
    </div>
  </div>
  {/* SERVICES START */}
  <div className="services-layer-alt mb-5">
    <div className="container">
      {/* <div className="section-title"> */}
        <div className="row">
          <div className="col-lg-6">
            <div className="left-title">
              <h5 className="subtitle">FARM SERVICES</h5>
              <h2>We take care of your comfort</h2>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-title">
              <p>
              At Green Farmhouse, we take pride in providing more than just a place to stay or visit as we offer a complete package of services and facilities designed to make your time here memorable and enjoyable.
              </p>
            </div>
          </div>
        </div>
      {/* </div> */}
      <div className="row">
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={gen} alt="generator facility at afrm" />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>Generator Backup</h4>
            <p>
             Reliable Power Supply, No Matter the Weather

            </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={parking} alt="parking facility at farm" />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>Ample Parking</h4>
            <p>
            Hassle-Free Parking for You and Your Guests
            </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={blue} alt="bluetooth device at farm" />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>Bluetooth Music Syatem</h4>
            <p>
            Groove to Your Favorite Tunes Anytime, Anywhere

            </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={wifi} alt="complimentary wifi facility at farm" />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>Free wifi</h4>
            <p>
            Stay Connected with Complimentary High-Speed Internet

            </p>
            </div>
           
        </div>
        </div>
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={camera} alt="..." />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>CCTV Cameras</h4>
            <p>
            Enhanced Security for Your Peace of Mind

            </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 aos-init" data-aos="fade-up">
          <div className="service-box-alt">
            <div className="circle-icon">
              <figure className="sb-icon-alt">
                <img src={dj} alt="farm under cctv survelliance" />
              </figure>
            </div>
            <div className="sb-icon-alt-text">
            <h4>Dance</h4>
            <p>
            Set the Mood with Dance and Music
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* SERVICES END */}
 
 
</section>
<Footer/>
    </div>
  )
}

export default About