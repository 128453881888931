import { React, useLayoutEffect } from 'react'
import Footer from "../component/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from "jquery";
import {Autoplay, Pagination } from "swiper/modules";

// import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../css/Service.css";


import whatsapp from "../images/whatsapp.png";
import farm5 from "../images/royal farm house/gardenview.jpg";

// -----------special icons-------------
import room from "../images/icons/hotel.png";
import hall from "../images/icons/city-hall.png";
import dish from "../images/icons/cooking.png";
import stay from "../images/icons/stay-at-home.png";
import fun from "../images/icons/children-activities.png";
import events from "../images/icons/confetti.png";
import {MetaTags} from "react-meta-tags";

const Services = () => {

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    });
  return (
    <div>
       {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Farm House for Family in Neral | Green Farm House
        </title>
        <meta name="title" title=" Farm House for Family in Neral | Green Farm House"/>
        <meta
          property="og:title"
          content=" Farm House for Family in Neral | Green Farm House"
        />
        <meta
          name="description"
          content="Discover the ideal farmhouse for family in Neral. Enjoy quality time together amidst nature's beauty. Book your getaway today!"
        />
       
        <meta
          property="og:description"
          content="Discover the ideal farmhouse for family in Neral. Enjoy quality time together amidst nature's beauty. Book your getaway today!"
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta property="article:tag" content="Farmhouse in Neral with Swimming Pool" />
        <link rel="canonical" href="https://www.greenfarmhouseneral.com/family-farm-house-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
    <div class="pages-hero">
            <div class="container">
                <div class="pages-title">
                <h5>Love with nature</h5>
                <h1 className='d-none'> Farm House for Family in Neral</h1>
                    <h2>Services</h2>
                    <p>We provide comfort stay at farm.</p>
                </div>
            </div>
        </div>

        <section>
  {/* SERVICES LAYER START */}
  <div className="service-wrapper-layer">
    <div className="container">
      <div className="section-title">
        <h2>SERVICES</h2>
        <h3>WHAT FARM OFFER</h3>
        <p>
        Every detail of your stay is thoughtfully curated to ensure that your time with us is an unforgettable celebration of love. 

        </p>
      </div>
      <div className="services-carousel">
      <Swiper
              slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={room} alt="ac rooms at farm" />
                      </figure>
                    </div>
                    <h4>Air conditioned rooms</h4>
                    <p>
                    Experience unparalleled comfort in our air-conditioned rooms that offer a cool and soothing sanctuary after a day of exploration and adventure. Relax in style and relish a restful night's sleep amidst modern amenities. 

                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={hall} alt="spacious halls at farm" />
                      </figure>
                    </div>
                    <h4>Spacious Hall</h4>
                    <p>
                    Our spacious hall is the perfect venue for special events, conferences, and gatherings. With ample room to accommodate your needs, it provides an ideal backdrop for creating memorable moments.
                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={dish} alt="fresh food at farm" />
                      </figure>
                    </div>
                    <h4>Delicious Food</h4>
                    <p>
                    Indulge your taste buds with a delectable journey at Green Farmhouse. Our skilled chefs prepare mouthwatering dishes that cater to various palates, ensuring your dining experience is nothing short of delightful.
                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={stay} alt="compfort stay at farm" />
                      </figure>
                    </div>
                    <h4>Compfort Stay </h4>
                    <p>
                    Indulge in the epitome of relaxation with our well-appointed accommodations, where every detail is designed to ensure your utmost comfort. Enjoy restful nights and wake up rejuvenated amidst the serenity of Green Farmhouse.

                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={fun} alt="fun activities at farm" />
                      </figure>
                    </div>
                    <h4>Fun activities</h4>
                    <p>
                    Explore a myriad of exciting outdoor activities that cater to all ages and preferences. From scenic nature walks to thrilling adventures, our resort offers endless opportunities for fun and adventure amidst the natural beauty that surrounds us.

                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
                  <div className="serv-box">
                    <div className="box-icon">
                      <figure className="sb-icon-alt">
                        <img src={events} alt="party and events at farm" />
                      </figure>
                    </div>
                    <h4>Party and Event</h4>
                    <p>
                    Explore a myriad of exciting outdoor activities that cater to all ages and preferences. From scenic nature walks to thrilling adventures, our resort offers endless opportunities for fun and adventure amidst the natural beauty that surrounds us.
                    </p>
                    <h6>
                      {/* <a href="products-details.html">Read More</a> */}
                    </h6>
                  </div>
                </div>
            
            </SwiperSlide>
          </Swiper>    
      </div>
    </div>
  </div>
  {/* SERVICES LAYER END */}
  <section>
  <div className="container mt-5 mb-5">
    <div className="row">
      <div className="col-lg-6">
        <div className="about-info-layer left-title">
          <h5 className="subtitle">Home feel amenities</h5>
          <h2>Experience our services</h2>
          <p>
          Farmhouse for family in Neral welcomes you to a place where lush landscapes, fresh air and a sense of tranquility come together to create an unforgettable experience. As you step onto our sprawling estate, you'll instantly feel the stresses of urban life melt away. Located away from the hustle and bustle of the city, the Green Farmhouse is a haven where time slows down, allowing you to reconnect with nature and rediscover a profound sense of calm. Whether you're seeking a peaceful weekend getaway, planning a special event, or looking for a unique corporate retreat, our picturesque farmhouse offers a multitude of possibilities. </p>
          <p>
          Farmhouse for family in Neral offers an array of services and amenities to cater to your needs. Whether you're a nature enthusiast looking to explore the surrounding trails, a food lover eager to savor locally sourced, farm-fresh cuisine, or a couple seeking an intimate wedding venue, we have something to offer everyone. Our dedicated team is here to ensure that your stay is as enjoyable and memorable as possible. Join us in this enchanting retreat and let the beauty of nature and the warmth of our hospitality envelop you in a world of serenity and relaxation. </p>
          <div className="more-details-layer">
            <div className="media contact-layer d-flex">
              <img src={whatsapp} className="mr-3" alt="whatsapp at  9892258567" />
              <div className="media-body">
                <h6>Contact us for Service</h6>
                <h3>
                  <a href=" https://wa.me/9892258567"> 9892258567</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 spacing-md">
        <figure className="about-img-layer">
          <img src={farm5} alt="open garden area at farm" />
          <div className="experience-layer">
            <h4>Open Garden Area</h4>
          </div>
        </figure>
      </div>
    </div>
  </div>
  {/* SERVICES START */}

  {/* SERVICES END */}
 
 
</section>
  {/* MAP START */}
  <div className="container mt-5 mb-5">
    <div className="section-title">
      <h2>FARM</h2>
      <h3>AROUND THE WORLD</h3>
    </div>
  
  </div>
  {/* MAP END */}
  <div className="counter-wrapper mt-5">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="counter-box">
           
            <div className="counter-caption">
              <div className="counter" data-count={1480}>
                50+
              </div>
              <p> Amenities</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="counter-box">
            <figure className="counter-icon">
            
            </figure>
            <div className="counter-caption">
              <div className="counter" data-count={5432}>
                2
              </div>
              <p>Farms</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="counter-box">
          
            <div className="counter-caption">
              <div className="counter" data-count={1200}>
                1
              </div>
              <p>Bunglaow</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="counter-box">
            {/* <figure className="counter-icon">
              <img src="images/icons/barn-white.png" alt="" />
            </figure> */}
            <div className="counter-caption">
              <div className="counter" data-count={6780}>
              20 
              </div>
              <p>Activities</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* MAP START */}
  <div className="bottom-map mb-5">
  <div className="map-box">
  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d482678.39528856345!2d72.7419470623007!3d19.06703648595441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7f1ed2c9cda69%3A0x3535611175ed927e!2sGreen%20Farm%20House!5e0!3m2!1sen!2sin!4v1694363163454!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 
 </div> </div>
  {/* MAP END */}
  {/*CONTACT FORM START */}
  <div className="container mb-5">
    <div className="shadow-box">
      <div className="section-title">
        <h2>CONTACT</h2>
        <h3>KEEP IN TOCH</h3>
        <p>
        Our team is here to assist you and answer any questions or concerns you may have.
        </p>
      </div>
      <Formik
                  initialValues={{
                    email: "",
                    Name: "",
                    Cnumber: "",
                    message: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.Name) {
                      errors.Name = "Required";
                    } else if (values.Name.length >= 10) {
                      errors.Name = "Enter A Name";
                    }

                    if (!values.Cnumber) {
                      errors.Cnumber = "Required";
                    } else if (values.Cnumber.length >= 11) {
                      errors.Name = "Invalid Contact Number";
                    }

                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // setTimeout(() => {

                    var body =
                      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Green Farmhouse Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +
                      values.Name +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +
                      values.email +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +
                      values.Cnumber +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +
                      values.message +
                      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Green Farmhouse</p></div></body></html>';

                    $.post(
                      "https://skdm.in/server/v1/send_lead_mail.php",
                      {
                        toEmail: "parvez_barge@yahoo.co.in",
                        fromEmail: "skdmlead@gmail.com",
                        bccMail: "skdmlead@gmail.com",
                        mailSubject: "New Lead genration",
                        mailBody: body,
                        accountName: "greenfarm",
                        accountLeadSource: "",
                      },

                      function (dataa, status) {
                        // console.log('data :' + dataa);
                        // console.log("name:" + custName);
                      }
                    );

                    alert(
                      "Your Form has Submitted Our team will contact with You  soon."
                    );

                    // e.preventDefault()

                    setSubmitting(false);
                    // Reset the form after submission
                    resetForm();

                    // }, 10);
                  }}
                >
                  <Form>
                    <div className="messages" />
                    <div className="controls">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Field
                              name="Name"
                              type="text"
                              className="form-control custom-form"
                              placeholder="*Name"
                            />
                            <ErrorMessage name="Name" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Field
                              name="Cnumber"
                              type="number"
                              className="form-control custom-form"
                              placeholder="Phone"
                            />
                            <ErrorMessage name="Cnumber" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <Field
                              name="email"
                              type="text"
                              className="form-control custom-form"
                              placeholder="*Email address"
                            />
                            <ErrorMessage name="email" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <Field
                              as="textarea"
                              name="message"
                              className="form-control custom-form"
                              placeholder="Message"
                              defaultValue={""}
                            />
                            <ErrorMessage name="message" component="div" />
                        

                            <div className="help-block with-errors" />
                          </div>
                        </div>
                 
                      </div>
                      <br />
                      <div className="row">
                <div className="col-md-12 btn-send">
                  <p>
                  <button className="btn btn-default contact_form_submit">
                              Send
                            </button>
                  </p>
                </div>
                <div className="col-sm-12">
                  <p className="required">* These fields are required.</p>
                </div>
              </div>
                    </div>
                  </Form>
                </Formik>
    </div>
  </div>
  {/*CONTACT FORM END */}
  
</section>
<Footer/>
    </div>
  )
}

export default Services;