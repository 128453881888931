import { React, useLayoutEffect } from "react";
import Footer from "../component/Footer";
import "../css/Contact.css";
import { MetaTags } from "react-meta-tags";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from "jquery";
const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <div>
      {/* ========= SEO ========== */}
      <MetaTags>
        <title>Farmhouse in Neral with Swimming Pool | Contact us</title>
        <meta
          name="title"
          title="Farmhouse in Neral with Swimming Pool | Contact us"
        />
        <meta
          property="og:title"
          content="Farmhouse in Neral with Swimming Pool | Contact us"
        />
        <meta
          name="description"
          content="Escape to a charming farmhouse in Neral with Swimming Pool. Experience tranquility and relaxation amidst lush greenery.  Perfect for weekend getaway!"
        />

        <meta
          property="og:description"
          content="Escape to a charming farmhouse in Neral with Swimming Pool. Experience tranquility and relaxation amidst lush greenery.  Perfect for weekend getaway!"
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta
          property="article:tag"
          content="Farmhouse in Neral with Swimming Pool"
        />
        <link rel="canonical" href="/farmhouse-neral-swimming-pool" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <div className="pages-hero-contact">
        <div className="container">
          <div className="pages-title contact-title">
            <h1 className="d-none">
              Farmhouse in Neral with Swimming Pool | Contact us
            </h1>
            <h2>Get In Touch</h2>
            <p>
              {" "}
              Our team is here to assist you and answer any questions or
              concerns you may have.
            </p>
            <p className="d-none">
              Green Farmhouse serves as a bridge between your desires for a
              remarkable experience and commitment to making it a reality. We
              appreciate your interest and stand ready to assist with any
              questions or reservations. You can reach us directly by phone for
              immediate assistance and reservation bookings. Alternatively, send
              us an email , and our dedicated team will promptly respond to any
              inquiries, whether they pertain to accommodation details, event
              reservations, or special requests. If you prefer a face-to-face
              discussion or wish to explore our facilities in person, we welcome
              you to schedule an appointment and visit us. Stay updated with our
              latest news, offers, and events by following us on Social Media.
              Our team is available during regular working hours, ensuring you
              can reach us conveniently. Utilize the provided location map to
              find your way to Green Farmhouse, and don't hesitate to get in
              touch. Your questions, comments, and feedback are invaluable to
              us, as we are dedicated to ensuring your experience at Green
              Farmhouse is nothing short of exceptional. Thank you for
              considering us as your destination of choice, and we eagerly await
              the opportunity to make your visit truly unforgettable.
            </p>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="contact-badge">
          <div className="row">
            <div className="col-lg-8">
              <div className="left-contact-badge">
                <h4>Send a Message</h4>

                <Formik
                  initialValues={{
                    email: "",
                    Name: "",
                    Cnumber: "",
                    message: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.Name) {
                      errors.Name = "Required";
                    } else if (values.Name.length >= 10) {
                      errors.Name = "Enter A Name";
                    }

                    if (!values.Cnumber) {
                      errors.Cnumber = "Required";
                    } else if (values.Cnumber.length >= 11) {
                      errors.Name = "Invalid Contact Number";
                    }

                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // setTimeout(() => {

                    var body =
                      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Green Farmhouse Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +
                      values.Name +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +
                      values.email +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +
                      values.Cnumber +
                      '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +
                      values.message +
                      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Green Farmhouse</p></div></body></html>';

                    $.post(
                      "https://skdm.in/server/v1/send_lead_mail.php",
                      {
                        toEmail: "parvez_barge@yahoo.co.in",
                        fromEmail: "skdmlead@gmail.com",
                        bccMail: "skdmlead@gmail.com",
                        mailSubject: "New Lead genration",
                        mailBody: body,
                        accountName: "greenfarm",
                        accountLeadSource: "",
                      },

                      function (dataa, status) {
                        // console.log('data :' + dataa);
                        // console.log("name:" + custName);
                      }
                    );

                    alert(
                      "Your Form has Submitted Our team will contact with You  soon."
                    );

                    // e.preventDefault()

                    setSubmitting(false);
                    // Reset the form after submission
                    resetForm();

                    // }, 10);
                  }}
                >
                  <Form>
                    <div className="messages" />
                    <div className="controls">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Field
                              name="Name"
                              type="text"
                              className="form-control custom-form"
                              placeholder="*Name"
                            />
                            <ErrorMessage name="Name" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Field
                              name="Cnumber"
                              type="number"
                              className="form-control custom-form"
                              placeholder="Phone"
                            />
                            <ErrorMessage name="Cnumber" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <Field
                              name="email"
                              type="text"
                              className="form-control custom-form"
                              placeholder="*Email address"
                            />
                            <ErrorMessage name="email" component="div" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <Field
                              as="textarea"
                              name="message"
                              className="form-control custom-form"
                              placeholder="Message"
                              defaultValue={""}
                            />
                            <ErrorMessage name="message" component="div" />
                        

                            <div className="help-block with-errors" />
                          </div>
                        </div>
                 
                      </div>
                      <br />
                      <div className="row">
                <div className="col-md-12 btn-send">
                  <p>
                  <button className="btn btn-default contact_form_submit">
                              Send
                            </button>
                  </p>
                </div>
                <div className="col-sm-12">
                  <p className="required">* These fields are required.</p>
                </div>
              </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="col-lg-4 spacing-md">
              <div className="right-contact-badge">
                <h4>Contact Info</h4>
                <p className="phone">
                  <a href="tel:052-5401-3322"> 9619856008 / 9869974037</a>
                </p>
                <p className="mail">
                  <a href="mailto:yatinpurav@gmail.com">yatinpurav@gmail.com</a>
                </p>
                <p className="address">
                  {" "}
                  <b>Green Farm House-</b> &nbsp;Plot No: 6, Anandvan Society,
                  Humberpada, Dahivili, Neral (Near Matheran) Distt-Raigarh
                </p>
                <p className="address">
                  {" "}
                  <b>Royal Farm House-</b> &nbsp;Plot No: 8, Anandvan Society,
                  Humberpada, Dahivili, Neral (Near Matheran) Distt-Raigarh
                </p>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61551300433278">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    {/* <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li> */}
                    <li>
                      <a href="https://www.instagram.com/greenfarmhousenarel/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://maps.app.goo.gl/2RnHope4oPHng5Su6">
                        <i className="fab fa-google" />
                      </a>
                    </li>
                  </ul>
                </div>
                <figure className="photo-contact">
                  <img
                    src="images/icons/phone-contact.png"
                    alt="contact at farm"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
