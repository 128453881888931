import React from "react";
import "../css/Home.css";
import Footer from "../component/Footer";
// import MyBackgroundImage from "../images/banner.jpg";
// import iconRight from "../images/icon-right.png";
import Carousel from "react-bootstrap/Carousel";
import cake from "../images/banner.jpg";
import park from "../images/gallery/garden.jpg";
import f1 from "../images/royal farm house/fruit1.jpg";
import f2 from "../images/royal farm house/fruit1.jpg";
import flower1 from "../images/royal farm house/flower.jpg";
import white from "../images/gallery/white-flower.jpg";
import whitepink from "../images/royal farm house/whie-pink.jpg";
import tree from "../images/gallery/tree.jpg";
import rose from "../images/royal farm house/pink-rose.jpg";

import about from "../images/banner.jpg";
import trees from "../images/tree.png";
import farm from "../images/barn.png";
import sign from "../images/signature.jpg";


// -----------special icons-------------
import swimm from "../images/icons/swimming-pool.png";
import garden from "../images/icons/playing.png";
import green from "../images/icons/house.png";
import fruits from "../images/icons/healthy-food.png";
import flower from "../images/icons/floral.png";
import food from "../images/icons/ramen.png";

// -------------Activities icons------------
import swim from "../images/icons/swimming.png";
import games from "../images/icons/cricket.png";
import seesaw from "../images/icons/seesaw.png";
import fish from "../images/icons/fishing.png";
import pluck from "../images/icons/apple-tree.png";
import horse from "../images/icons/equestrian.png";
import swing from "../images/icons/swing.png";
// -------------images--------------------
import fruitpluck from "../images/gallery/fruit-pluck.jpg";
import sees from "../images/gallery/seesaw.jpg";
import swings from "../images/gallery/swing.jpg";
import cricket from "../images/gallery/cricket.jpg";
import fishing from "../images/gallery/fishing.jpg";
import hors from "../images/royal farm house/horse-riding.jpg";
import swm from "../images/gallery/swimmingpool.jpg";

// -------------swiper------------------
import {Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {Link} from 'react-router-dom';
import {MetaTags} from "react-meta-tags";
const Home = () => {
  return (
    <div>

        {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Farm House in Neral | Green Farm House
        </title>
        <meta name="title" title="Farm House in Neral | Green Farm House"/>
        <meta
          name="description"
          content="Escape to a serene Farm House in Neral. Experience nature's beauty and tranquility on your getaway. Book your stay now! "
        />
         <meta
          property="og:title"
          content=" Farm House in Neral | Green Farm House"
        />
        <meta
          property="og:description"
          content="Escape to a serene Farm House in Neral. Experience nature's beauty and tranquility on your getaway. Book your stay now!"
        />
        <meta
          name="keywords"
          content=" Farm House in Neral,
          Farm House near Neral,     
           Farm House for Family in Neral,
          Farmhouse in Neral with Swimming Pool,
          2bhk Villa in Neral, 
           4bhk Villa near Neral"
        />
<meta name="google-site-verification" content="uB0GlnIehCrX7BgQXxrPIcGNxPFj_jFARZVUCsXtebM" />
        <meta property="article:tag" content="4bhk Villa near Neral" />
        <meta property="article:tag" content=" 2bhk Villa in Neral" />
        <meta property="article:tag" content="Farmhouse in Neral with Swimming Pool" />
        <link rel="canonical" href="https://www.greenfarmhouseneral.com/farm-house-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Farm House in Neral | Green Farm House" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
      <Carousel>
        
        <Carousel.Item  id="firstSlide">
          {/* <Zoom left cascade> */}
          {/* <img className="d-block w-100 h-100" src={banner1} alt="" /> */}
          
          {/* </Zoom> */}
          <Carousel.Caption>
            <div className="overlay1">
            <div className="container slider-caption">
              <h5 className="subtitle">Your Gateway to Green Living </h5>
              <h1 className="d-none"> Farm House in Neral</h1>
              <h2>Bringing you at Lush green Surrounding</h2>
              <p>
              Farmhouse in Neral offers a harmonious blend of tranquility and luxury as you are surrounded by breathtaking landscapes 
              </p>

              <Link to="/farm-house-near-neral"  className="btn btn-default btn-outline">
              LEARN MORE</Link>
              {/* <a
               
                href="about-us.html"
                role="button"
              >
              
              </a> */}
            </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id="secondSlide">
          {/* <Zoom> */}

          {/* <img
            className="d-block w-100 h-100"
            src={banner2}
            alt="invisible braces in mumbai"
          /> */}
          {/* </Zoom> */}
          <Carousel.Caption>
            <div className="container slider-caption">
              <h5 className="subtitle">Where Every Stay is Green </h5>
              <h2>Feel the real beauty of nature</h2>
              <p>
              Farmhouse in Neral offers a harmonious blend of tranquility and luxury as you are surrounded by breathtaking landscapes.
              </p>
              <Link to="/farm-house-near-neral"  className="btn btn-default btn-outline">
              LEARN MORE</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id="thirdSlide">
          {/* <Zoom> */}
          {/* <img className="d-block w-100 h-100" src={banner3} alt="" /> */}
          {/* </Zoom> */}

          <Carousel.Caption>
            <div className="container slider-caption">
              <h5 className="subtitle">Your Eco-Friendly Retreat</h5>
              <h2>Plant tree and save nature</h2>
              <p>
              Farmhouse in Neral offers a harmonious blend of tranquility and luxury as you are surrounded by breathtaking landscapes 
              </p>
              <Link to="/farm-house-near-neral"  className="btn btn-default btn-outline">
              LEARN MORE</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="home-about">
              <h2>About Us</h2>
              <h3 >
              Where Nature Meets Comfort 
              </h3>
              <p>
              Farmhouse in Neral offers a harmonious blend of tranquility and luxury as you are  surrounded by breathtaking landscapes and find yourself immersed in a world where time slows down, allowing you to unwind and rejuvenate. 
              </p>
              <p>
              By seeking a peaceful escape from the hustle and bustle of daily life or a luxurious retreat to indulge in, our destination promises an unforgettable experience that transcends ordinary getaways. 
              </p>
              <div className="hb-box d-flex flex-row">
                <div className="hb-inner">
                  <figure className="hb-icon">
                    <img src={trees} alt="trees surround farm" />
                  </figure>
                  <h5>Green Farm</h5>
                  <p>2BHK Bunglow</p>
                </div>
                <div className="hb-inner spacing-sm">
                  <figure className="hb-icon">
                    <img src={farm} alt="4bhk farm" />
                  </figure>
                  <h5>Royal Farm</h5>
                  <p>4 room Farm House</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <figure className="home-about-img">
              <img src={about} alt="farm house in neral" />
              <div className="image-badget">
           
                <h4>Our Values</h4>
                <p>
                At our farm house stay, we embrace the values of rustic tranquility, nature's embrace, and a warm, welcoming haven for all who seek an escape from the ordinary.
                </p>
              </div>
            </figure>
          </div>
        </div>
      </div>

      {/* ---------------------------------farms details------------ */}

      <section>
        <div className="container">
          <div className="why-us-layer mt-5 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="farm-img">
                  <div className="overlay">
                    <h2>Green Farm House</h2>
                    <Link className="btn btn-default" to="/2bhk-villa-neral" role="button">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="farm-img1">
                  <div className="overlay">
                    <h2>Royal Farm House</h2>
                    <Link className="btn btn-default" to="/4bhk-villa-near-neral" role="button">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="services-layer mb-5">
        <div className="container">
          <div className="section-title">
            <h2>Specialities</h2>
            <h3>What You enjoy</h3>
            <p>
            We offer a wide range of tailored experiences to ensure your stay is memorable.

            </p>
          </div>
          <div className="col-row">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="row">
                <div className="col-md-6 col-lg-6" data-aos="fade-up">
                  <div className="service-box">
                    <div className="media">
                      <img src={flower} className="sb-icon" alt="farm with flower plants" />
                    </div>
                    <div className="media-body sb-caption">
                      
                      <h4>Different flower Plants</h4>
                      
                      <p>
                      Explore a colorful array of vibrant flower plants that paint our landscape with nature's finest hues.

                      </p>
                   
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-6 aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={fruits} className="sb-icon" alt="farm with fruit trees" />
                      </div>
                      <div className="media-body sb-caption">
                        <h4>Fresh Fruits & Vegetables</h4>
                        <p>
                        Savor the goodness of locally sourced,  produce, right from our garden to your plate.

                        </p>
                      </div>
                    
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 aos-init" data-aos="fade-up">
                  <div className="service-box">
                    <div className="media">
                      <img src={green} className="mr-3 sb-icon" alt="farm with green surroundings" />
                      </div>
                      <div className="media-body sb-caption">
                        <h4>Lush Green Surrounding</h4>
                        <p>
                        Find solace in the tranquil, verdant landscapes that envelop our property, a haven for nature enthusiasts.

                        </p>
                      </div>
                  
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 aos-init" data-aos="fade-up">
                  <div className="service-box">
                    <div className="media">
                      <img src={swimm} className="mr-3 sb-icon" alt="farm with swimming pool" />
                      </div>
                      <div className="media-body sb-caption">
                        <h4>Swimming Pool</h4>
                        <p>
                        Take a refreshing dip in our crystal-clear pool, offering a perfect oasis for unwinding and leisure.

                        </p>
                      </div>
                    
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 aos-init" data-aos="fade-up">
                  <div className="service-box">
                    <div className="media">
                      <img src={food} className="mr-3 sb-icon" alt="farm with deloicious food" />
                      </div>
                      <div className="media-body sb-caption">
                        <h4>Mouth Watering Food</h4>
                        <p>
                        Indulge your taste buds in a delectable journey of flavors with our expertly crafted cuisine.

                        </p>
                      </div>
                   
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 aos-init" data-aos="fade-up">
                  <div className="service-box">
                    <div className="media">
                      <img src={garden} className="mr-3 sb-icon" alt="farm with garden" />
                      </div>
                      <div className="media-body sb-caption">
                        <h4>Play Area</h4>
                        <p>
                        From kids to adults, our well-equipped play area offers endless entertainment and recreation.

                        </p>
                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="why-us-layer mt-5 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="why-us-img-bg"></div>
              </div>
              <div className="col-lg-6">
                <div className="why-us-left">
                  <div className="why-us-info">
                    <h2>WHY CHOOSE US</h2>
                    <p>
                    Farmhouse in Neral is committed to exceptional hospitality ensuring that every stay with them is characterized by warmth, comfort and personalized service. Nestled amidst pristine natural surroundings, our property offers a unique opportunity to immerse yourself in the breathtaking beauty of nature. 

                    </p>
                    <p>
                    Farmhouse in Neral caters to guests of all ages, providing a wide range of activities and amenities to ensure everyone's entertainment.
                    </p>
                    <figure className="signature">
                      <img src={sign} alt="owner sign" />
                    </figure>
                    <Link className="btn btn-default" to="/Contact" role="button">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-5 mb-5">
        <div className="section-title">
          <h2>View </h2>
          <h3>Our Gallery</h3>
          <p>
          Step into our visual world and immerse yourself in the beauty and charm of our destination through our carefully curated gallery. 

          </p>
        </div>
        <div className="team-carousel">
          <Swiper
            // navigation={true}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={flower1} alt="flower plants  in farmhouse" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={cake} alt="garden in farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={park} alt="farm house with park" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f1} alt="beautiful flowers" />
                 
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={whitepink} alt="pink rose" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={rose} alt="roses plants at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={white} alt="white roses plants" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={f2} alt="red roses flowers" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="team-portrait">
                  <img src={tree} alt="green trees at farm" />
                  {/* <div className="tp-caption">
                    <h4>
                      Carlos <br /> <strong>RODRIGUEZ</strong>
                    </h4>
                    <p>Principal Farmer</p>
                  </div> */}
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

       
      </div>

      <div className="testimonials-layer mt-5 mb-5">
        <div className="section-title">
          <h2>WHAT OUR CLIENTS SAYS</h2>
        </div>
        <div className="container">
          <div className="team-carousel">
            <Carousel>
              <Carousel.Item>
                {/* <Zoom left cascade> */}
                <div className="item">
                  <div className="testimonial-box">
                    <p>
                    I visited twice to this place in last month. 2nd time I visited with family and 10 months old daughter. I must say this place is amazing. Staff is very supportive. Staff help us in all possible way to prepare food for my daughter. They allow us in kitchen so we can personally prepare and supervise food which was been prepared for my 10 months daughter. Absolutely best place to visit with family. Kids and pet friendly place. Food is so yummy and hospitality service is also best. It gives a feeling like it's your own property. Thank you so much for such a priceless
                    </p>

                    <h3>
roshan patne</h3>
                  
                    <i className="fas fa-quote-left" />
                  </div>
                </div>
                {/* </Zoom> */}
              </Carousel.Item>

              <Carousel.Item>
                {/* <Zoom> */}

                <div className="item">
                  <div className="testimonial-box">
                    <p>
                    Best place to spend your weekend..very peaceful and service is best..Food is great..all people here are very helpful.
                    </p>

                    <h3>Nikita Manjrekar</h3>
                    {/* <p className="profession">Food Collector</p> */}
                    <i className="fas fa-quote-left" />
                  </div>
                </div>
                {/* </Zoom> */}
              </Carousel.Item>

              <Carousel.Item>
                {/* <Zoom> */}
                <div className="item">
                  <div className="testimonial-box">
                    <p>
                    Best resort As it is going to be more further Playing Activities to be installed had a great time and food is excellent good experience ,come and enjoy your weekend.
                    </p>

                    <h3>Manoj Chavan</h3>
                    {/* <p className="profession">Food Collector</p> */}
                    <i className="fas fa-quote-left" />
                  </div>
                </div>
                {/* </Zoom> */}
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="section-title">
          <h2>View </h2>
          <h3>Activities</h3>
          <p>
          Indulge in a diverse array of experiences tailored to suit every preference.
          </p>
        </div>
        <div className="team-carousel">
          <Swiper
            // navigation={true}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className="item">
                <div className="item">
                  <figure className="news-thumbnail">
                    <a href="blog-single.html">
                      <img src={swm} alt="farm with swimming pool" />
                    </a>
                    <div className="news-caption">
                      <div className="date-layer">
                        {/* <figure className="ib-icon"> */}
                        <img src={swim} alt="farm with kids pool" />
                        {/* </figure> */}
                      </div>
                      <h4>
                        <a href="blog-single.html">Swimming</a>
                      </h4>
                    
                    </div>
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="item">
                  <figure className="news-thumbnail">
                    <a href="blog-single.html">
                      <img src={hors} alt="horse riding at farm" />
                    </a>
                    <div className="news-caption">
                      <div className="date-layer">
                        <img src={horse} alt="enjoy horse riding at farm" />
                      </div>
                      <h4>
                        <a href="blog-single.html">Horse Riding</a>
                      </h4>
                    
                    </div>
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="item">
                  <figure className="news-thumbnail">
                    <a href="blog-single.html">
                      <img src={sees} alt="seesaw at farm" />
                    </a>
                    <div className="news-caption">
                      <div className="date-layer">
                        <img src={seesaw} alt="seesaw at farm" />
                      </div>
                      <h4>
                        <a href="blog-single.html">Sea Saw</a>
                      </h4>
                     
                    </div>
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="item">
                  <figure className="news-thumbnail">
                    <a href="blog-single.html">
                      <img src={swings} alt="swings at garden" />
                    </a>
                    <div className="news-caption">
                      <div className="date-layer">
                        <img src={swing} alt="swings at garden" />
                      </div>
                      <h4>
                        <a href="blog-single.html">Swing</a>
                      </h4>
                     
                    </div>
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="news-thumbnail">
                  <a href="blog-single.html">
                    <img src={fishing} alt="fishing at farm" />
                  </a>
                  <div className="news-caption">
                    <div className="date-layer">
                      <img src={fish} alt="fishing at farm" />
                    </div>
                    <h4>
                      <a href="blog-single.html">Fishing</a>
                    </h4>
                   
                  </div>
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="news-thumbnail">
                  <a href="blog-single.html">
                    <img src={fruitpluck} alt="fruitplucking at farm" />
                  </a>
                  <div className="news-caption">
                    <div className="date-layer">
                      <img src={pluck} alt="fruitplucking at farm" />
                    </div>
                    <h4>
                      <a href="blog-single.html">Fruit Bearing</a>
                    </h4>
                  
                  </div>
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <figure className="news-thumbnail">
                  <a href="blog-single.html">
                    <img src={cricket} alt="games at farm" />
                  </a>
                  <div className="news-caption">
                    <div className="date-layer">
                      <img src={games} alt="games at farm" />
                    </div>
                    <h4>
                      <a href="blog-single.html">Indoor/Outdoor Games</a>
                    </h4>
                 
                  </div>
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Home;
