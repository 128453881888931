import React, { useState } from "react";
import "../css/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../images/Green Farm (1).png";
const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                  <Nav.Link href="/">
                    <Link to="/">Home</Link>
                  </Nav.Link>
                  <Nav.Link href="farm-house-near-neral">
                    <Link to="/farm-house-near-neral">About Us</Link>
                  </Nav.Link>
                  <Nav.Link href="/family-farm-house-neral">
                    <Link to="/family-farm-house-neral">Services</Link>
                  </Nav.Link>
                  <button className="dropdownShow">
                    Farms
                    <div className="dorpdown">
                      <div className="dropdown_list">
                        <Nav.Link href="/2bhk-villa-neral">
                          <Link to="/2bhk-villa-neral">Green Farm House</Link>
                        </Nav.Link>
                        <Nav.Link href="4bhk-villa-near-neral">
                          <Link to="/4bhk-villa-near-neral">Royal Farm House</Link>
                        </Nav.Link>
                      </div>
                    </div>
                  </button>
                  {/* <Nav.Link href="/home-care-nursing-services">
                      <Link to="/home-care-nursing-services">Gallery</Link>
                    </Nav.Link> */}
                  <Nav.Link href="/Contact">
                    <Link to="/Contact">Contact Us</Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
