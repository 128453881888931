import React from 'react'
import "../css/Footer.css";
import Logo from "../images/Green Farm.png";
import {Link} from "react-router-dom";
const Footer = () => {
  return (
    <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fas fa-map-marker-alt" />
            <div className="cta-text">
              <h4>Find us</h4>
              <span>
Anandvan Society,
Humberpada, Dahivili,
Neral (Near Matheran)
Distt-Raigarh</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fas fa-phone" />
            <div className="cta-text">
              <h4>Call us</h4>
              <span>9892258567 /  9619856008</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="far fa-envelope-open" />
            <div className="cta-text">
              <h4>Mail us</h4>
              <span>yatinpurav@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="index.html">
                <img
                  src={Logo}
                  className="img"
                  alt="logo"
                />
              </a>
            </div>
            {/* <div className="footer-text">
              <p>
                Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
                do eiusmod tempor incididuntut consec tetur adipisicing
                elit,Lorem ipsum dolor sit amet.
              </p>
            </div> */}
                <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Contact</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              9869974037 /
              9619856008
              </p>
            </div>
          
          </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
              <Link to="/">Home</Link>
              </li>
                <li>
              <Link to="/2bhk-villa-neral">Green Farm House</Link>
              </li>
              <li>
              <Link to="/farm-house-near-neral">About Us</Link>
              </li>
             
              <li>
              <Link to="/4bhk-villa-near-neral">Royal Farm House</Link>
              </li>
              <li>
              <Link to="/family-farm-house-neral">Services</Link>
              </li>
              <li>
              <Link to="/Contact">Contact Us</Link>
              </li>
             
            
            </ul>
          </div>
          <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="https://www.facebook.com/profile.php?id=61551300433278">
                <i className="fab fa-facebook-f facebook-bg" />
              </a>
              <a href="https://www.instagram.com/greenfarmhousenarel/">
                <i className="fab fa-instagram twitter-bg" />
              </a>
              <a href="https://maps.app.goo.gl/2RnHope4oPHng5Su6">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Green Farm House</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Plot No: 6,
Anandvan Society,
Humberpada, Dahivili,
Neral (Near Matheran)
Distt-Raigarh
              </p>
            </div>
            <div className="footer-widget-heading">
              <h3>Royal Farm House</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Plot No: 8,
Anandvan Society,
Humberpada, Dahivili,
Neral (Near Matheran)
Distt-Raigarh
              </p>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2018, All Right Reserved{" "}
              <a href="">Green Farm House</a>
            </p>
          
          </div>
        </div>
        <div className="col-xl-6 col-lg-6  text-right">
          <div className="footer-menu">
          <div className="copyright-text">
          <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<a href="#0" className="cd-top cd-is-visible">
  Top
</a>

    </div>
  )
}

export default Footer