import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from "./component/NavBar"
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Greenfarm from "./pages/Greenfarm";
import Royalfarm from './pages/Royalfarm';
import Contact from './pages/Contact';
import Errorpage from './pages/Errorpage';
// import Gallery from './component/Gallery';

function App() {
  return (
    <BrowserRouter>
    <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/farm-house-near-neral' element={<About/>}/>
         <Route path='/family-farm-house-neral' element={<Services/>} />
         <Route path='/2bhk-villa-neral' element={<Greenfarm/>} />
         <Route path='/4bhk-villa-near-neral' element={<Royalfarm/>} />
         <Route path='/Contact' element={<Contact/>} /> 
         <Route path='/*' element={<Errorpage/>} />
         {/* <Route path='/Gallery' element={<Gallery/>} />
         <Route path='Contact' element={<Contact/>} />  */}

      </Routes>
    </BrowserRouter>
  );
}

export default App;
